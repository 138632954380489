document.addEventListener('DOMContentLoaded', async () => {
    await import('normalize.css');
    await import('../../Classes/Mask/maskNumber');

    const contacts = document.getElementById('contacts');
    if (contacts instanceof HTMLElement) {
        const {Info} = await import('../../Classes/Contacts/Info');
        new Info();
    }

    const elementHeader = document.getElementById('fix-header');
    if (elementHeader instanceof HTMLElement) {
        const {ScrollHandler} = await import('../../Classes/Header/Fixed.js');
        new ScrollHandler(elementHeader);
    }

    const mainSlider = document.getElementById('main-banners');
    if (mainSlider instanceof HTMLElement) {
        const {BannersSlider} = await import('../../Classes/Banner/BannersSlider.js');
        new BannersSlider('.swiper-container-slider');
    }

    const mainPage = document.getElementById('main');
    if (mainPage instanceof HTMLElement) {
        const {default: PromoSlider} = await import('../../Classes/Main/Promo/Slider.js');
        new PromoSlider('.swiper-container-promo');

        // Отправка формы ипотеки
        const {SubmitFormMortgage} = await import('../../Classes/Api/SubmitFormMortgage.js');
        const formMortgage = document.getElementById('mortgage-form');
        SubmitFormMortgage(formMortgage);

        // Отправка формы обратной связи
        const {SubmitFormFeedback} = await import('../../Classes/Api/SubmitFormFeedback.js');
        const formFeedback = document.getElementById('feedback-form');
        SubmitFormFeedback(formFeedback);
    }

    const complexes = document.getElementById('detail-complex-info');
    if (complexes instanceof HTMLElement) {
        // Раскрытие галереи
        const {ShowMorePictures} = await import('../../Classes/Complexes/ShowMorePictures');
        const galleryGrid = document.querySelector('.complex-detail-gallery__grid');
        const buttonBackground = document.querySelector('.complex-detail-gallery__grid-gradient');
        const button = document.getElementById('showMoreBtn');
        if (galleryGrid && buttonBackground && button) {
            new ShowMorePictures(galleryGrid, button, buttonBackground);
        }
        // Модальное окно заявки на ипотеку
        const {ShowModal} = await import('../../Classes/Complexes/ShowModal');
        const buttonShowModal = document.querySelectorAll('.mortgage-program__info-button');
        new ShowModal('#my-modal-mortgage', '#modal-backdrop-mortgage', buttonShowModal);
        // Слайдер ход строительства
        const {Slider} = await import('../../Classes/Complexes/Slider');
        new Slider('.swiper-container-progress-build');
        // Слайдер парковка
        const {SliderParcing} = await import('../../Classes/Complexes/SliderParcing');
        new SliderParcing('.swiper-container-complex-detail-parking');
        // Показ крупных слайдов галереи
        const {ShowMoreSliders} = await import('../../Classes/Complexes/ShowMoreSliders');
        const slideShowMoreSlidersGallery = document.querySelectorAll('.complex-detail-gallery__grid-contain');
        new ShowMoreSliders(
            '#modal-backdrop-mortgage',
            '.close-button-slide-complex',
            '#more-slides-gallery',
            slideShowMoreSlidersGallery,
            '.close-button-slide-gallery',
            '.complex-detail-large-sliders__contain-slides'
        );
        // Показ крупных слайдов хода строительства
        const {ShowMoreSlidersProgress} = await import('../../Classes/Complexes/ShowMoreSlidersProgress');
        const slideShowMoreSlidersProgress = document.querySelectorAll('.complex-detail-progress-build__sliders-item');
        new ShowMoreSlidersProgress(
            '#more-slides',
            '#modal-backdrop-mortgage',
            slideShowMoreSlidersProgress,
            '.close-button-slide-complex',
            '.complex-detail-large-sliders__contain-progress'
        );
        // Отправка формы ипотеки о страницы ЖК
        const {SubmitFormMortgageComplex} = await import('../../Classes/Api/SubmitFormMortgageComplex.js');
        const form = document.getElementById('mortgage-form-complex');
        SubmitFormMortgageComplex(form);
        // Отправка формы экскурсии
        const {SubmitFormExcursion} = await import('../../Classes/Api/SubmitFormExcursion.js');
        const formFeedback = document.getElementById('excursion-form');
        SubmitFormExcursion(formFeedback);
    }

    const header = document.getElementById('header');
    if (header instanceof HTMLElement) {
        const {menuModal} = await import('../../Classes/Header/Menu.js');
        new menuModal('#modal-menu', '#open-button-menu');
        new menuModal('#modal-menu', '#open-button-menu-fixed');
    }

    const mainGroupCompany = document.getElementById('group-company');
    if (mainGroupCompany instanceof HTMLElement) {
        const {Slider} = await import('../../Classes/Main/Slider.js');
        new Slider('.swiper-container');
    }

    const detailPageRent = document.getElementById('rent-detail');
    if (detailPageRent instanceof HTMLElement) {
        const {default: Slider} = await import('../../Classes/Rent/Slider.js');
        new Slider('.swiper-container');

        const {Modal} = await import('../../Classes/Header/Modal.js');
        new Modal('#my-modal', '#modal-backdrop', '#open-modal-button');

        // Отправка формы аренды
        const {SubmitFormRent} = await import('../../Classes/Api/SubmitFormRent.js');
        const formRent = document.getElementById('submit-rent');
        SubmitFormRent(formRent);
    }

    const MapElem = document.getElementById('map');
    if (MapElem instanceof HTMLElement) {
        const {Map} = await import('../../Classes/Map/Map.js');
        const radioButtons = document.querySelectorAll('.footer-info__contacts-buttons input[type="radio"]');
        const apiKey = '1b8afe6f-d238-4b72-895d-28d5d38d268e';
        let map;
        let YMapMarker;
        let markers = [];
        new Map(radioButtons, map, YMapMarker, markers, apiKey);
    }

    const projectPage = document.getElementById('projects');
    if (projectPage instanceof HTMLElement) {
        const {default: Gallery} = await import('../../Classes/Projects/Gallery.js');
        const galleryItems = document.querySelectorAll('.gallery__item');
        const galleryContain = document.querySelector('.gallery-detail__contain');
        const detailGallery = document.getElementById('more-slides');
        new Gallery(
            detailGallery,
            '.gallery-detail__wrapper',
            galleryItems,
            '.gallery-detail__buttons-prev',
            '.gallery-detail__buttons-next',
            galleryContain
        );
    }

    const Mortgage = document.getElementById('mortgage');
    if (Mortgage instanceof HTMLElement) {
        const {MaskCost} = await import('../../Classes/Mask/maskCost.js');
        const apartmentsCost = document.getElementById('mortgage-price');
        const downPayment = document.getElementById('mortgage-pay');
        new MaskCost(apartmentsCost, downPayment);
    }

    const flatogram = document.querySelector('.flatogram-widget');
    if (flatogram instanceof HTMLElement) {
        const {Flatogram} = await import('../../Classes/Flatogram/Flatogram.js');
        new Flatogram(flatogram);
    }
});
